import * as React from 'react'
import { Div } from 'glamorous'

import { laptop, mobile } from 'shared/media-queries'

const defaults = {
	fontSize: '24px',
	lineHeight: '1.33',
}

const H3 = props => {
	const css = {
		...defaults,
		[laptop]: {
			fontSize: '20px',
		},
		[mobile]: {
			fontSize: '18px',
		},
		...props.additonalCss,
	}
	return <Div {...props} css={css} />
}

export default H3
