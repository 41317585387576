import React from 'react'
import { Div } from 'glamorous'
import spencerLarge from '../img/bradley-concrete/spencer-large.png'
import spencerSmall from '../img/bradley-concrete/spencer-small.png'
import bradleyConcreteLogo from '../img/bradley-concrete/bradley-concrete-logo-white@2x.png'
import completeMillworkThumb from '../img/complete-millwork-video-thumbnail.png'
import canterburyThumb from '../img/canterbury-video-thumbnail.png'
import innovativeThumb from '../img/innovative-video-thumbnail.png'
import VideoQuote from './video-quote'
import VideoThumbnails from './video-thumbnails'

import { isUSA } from 'shared/get-country'

import { mobile, tablet } from 'shared/media-queries'

const Spacer = () => (
	<Div
		height="30px"
		css={{
			[mobile]: { height: '3px' },
		}}
	/>
)

const SubsVideoQuotes = country => {
	return isUSA(country) ? (
		<>
			<VideoQuote
				largeImg={spencerLarge}
				smallImg={spencerSmall}
				videoId="m66a48ycjx"
				logo={{ src: bradleyConcreteLogo, width: '111px', height: '20px' }}
				leftQuote
			/>
			<Spacer />
			<VideoThumbnails
				thumbnails={[
					{
						imgSrc: completeMillworkThumb,
						videoId: 'snufv3f3jo',
					},
					{
						imgSrc: canterburyThumb,
						videoId: 'v4w9iv98c9',
					},
					{
						imgSrc: innovativeThumb,
						videoId: 'qrxlgzbgm9',
					},
				]}
			/>
			<Div
				height="100px"
				css={{
					[tablet]: { height: '58px' },
				}}
			/>
		</>
	) : null
}

export default SubsVideoQuotes
