import domeLogo from './img/dome-logo.png'
import dustinM from './img/dustin-m.png'
import plazaLogo from './img/plaza-logo.png'
import stanley from './img/stanley.png'
import shawmutLogo from './img/shawmut-logo.png'
import accoLogo from './img/acco-logo.png'
import maggie from './img/maggie.png'
import douglasBallou from './img/douglas-ballou.png'
import bcciLogo from './img/bcci/bcci-logo.png'
import coryWilson from './img/cory-wilson.png'
import shielSextonLogo from './img/shiel-sexton-logo.png'
import mattVentimiglia from './img/matt-ventimiglia.png'
import holtLogo from './img/holt-logo-dark.png'
import stephenFontana from './img/stephen-fontana.png'
import henselPhelpsLogo from './img/hensel-phelps-logo.png'
import kyleBonde from './img/kyle-bonde.png'
import shomratAcco from './img/shomrat-acco.png'
import austinShawmut from './img/austin-shawmut.png'
import skanskaLogo from './img/skanska-logo.png'
import hoarLogo from './img/hoar-logo.png'
import alstonLogo from './img/alston/alston-logo@2x.png'
import novoLogo from './img/novo-logo.png'

import { isUSA } from 'shared/get-country'

export const logos = {
	skanska: {
		src: skanskaLogo,
		width: '135px',
		height: '20px',
	},
	hoar: {
		src: hoarLogo,
		width: '70px',
		height: '36px',
	},
	alston: {
		src: alstonLogo,
		width: '72px',
		height: '26px',
	},
	henselPhelps: {
		src: henselPhelpsLogo,
		height: '25px',
		width: '156px',
	},
	novo: {
		src: novoLogo,
		height: '26px',
		width: '93px',
	},
	acco: {
		src: accoLogo,
		height: '33px',
		width: '33px',
	},
}

const quoteList = country => {
	const bidOrTender = isUSA(country) ? 'bid' : 'tender'
	const bidOrTenderU = isUSA(country) ? 'Bid' : 'Tender'
	return {
		holt: {
			logo: {
				src: holtLogo,
				height: '41px',
				width: '42px',
			},
			quote: `BuildingConnected provides a streamlined ${bidOrTender} management process that takes the pressure off of the estimating team. Everything we need is a click away.`,
			imgSrc: stephenFontana,
			name: 'Stephen Fontana',
			title: 'Estimator',
		},
		shawmut: {
			logo: {
				src: shawmutLogo,
				height: '20px',
				width: '115px',
			},
			quote: `With BuildingConnected, you can get the information and documentation to the subcontractors in a matter of minutes. It really speeds up the process.`,
			imgSrc: maggie,
			name: 'Maggie Coppa',
			title: 'Junior Estimator',
		},
		plaza: {
			logo: {
				src: plazaLogo,
				height: '40px',
				width: '69px',
			},
			quote: `We no longer have to cold call hundreds of subcontractors to find out whether they're submitting ${bidOrTender}s.`,
			imgSrc: stanley,
			name: 'Stanley Abellard',
			title: 'Estimator',
		},
		dome: {
			logo: {
				src: domeLogo,
				height: '36px',
				width: '71px',
			},
			quote: `The amount of time I save using BuildingConnected has increased the number of jobs I ${bidOrTender} each month by over 50%.`,
			imgSrc: dustinM,
			name: 'Dustin Marzalek',
			title: 'Associate Director',
		},
		bcci: {
			logo: {
				src: bcciLogo,
				height: '50px',
				width: '55px',
			},
			quote: `BuildingConnected is an extremely easy program to use to manage the entire estimating process from the day you get the drawings to receiving the final ${bidOrTender}s from the subs - everything's in one place.`,
			imgSrc: coryWilson,
			name: 'Cory Wilson',
			title: 'Estimator',
		},
		shiel: {
			logo: {
				src: shielSextonLogo,
				height: '35px',
				width: '18px',
			},
			quote: `I love BuildingConnected because our subcontractors find it easy to use, I find it easy to use, and it does everything I need an Invitation to ${bidOrTenderU} tool to do and more.`,
			imgSrc: mattVentimiglia,
			name: 'Matt Ventimigli',
			title: 'Director of Preconstruction',
		},
		hensel: {
			logo: logos.henselPhelps,
			quote: `No internal database management; emails and phone numbers actually work.  Can train someone in 5 minutes; just hit the orange button.`,
			imgSrc: kyleBonde,
			name: 'Kyle Bonde',
			title: 'Lead Estimator',
		},
		novo: {
			logo: logos.novo,
			quote: `Before BuildingConnected, setting up a project and communicating with subs was a long, drawn out process. Now, it’s literally a matter of minutes.`,
			imgSrc: douglasBallou,
			name: 'Douglas Ballou',
			title: 'Project Executive & Preconstruction Manager',
		},
		acco: {
			logo: logos.acco,
			quote: `Great organisational tool to manage all of my projects. It's cleaner and easier to navigate than any other solution out there.`,
			imgSrc: shomratAcco,
			name: 'Shomrat Sarkar',
			title: 'Project Manager',
		},
		shawmut2: {
			logo: {
				src: shawmutLogo,
				height: '20px',
				width: '115px',
			},
			quote: `BuildingConnected has been such a great tool to get our name out there, especially in areas where we don't have a lot of sub coverage.`,
			imgSrc: austinShawmut,
			name: 'Austin Chun',
			title: 'Junior Estimator',
		},
	}
}

export const quotes = country => [
	quoteList(country).holt,
	quoteList(country).shawmut,
	quoteList(country).plaza,
	quoteList(country).dome,
	quoteList(country).bcci,
	quoteList(country).shiel,
	quoteList(country).hensel,
	quoteList(country).novo,
]

export const otherQuotes = country => [
	quoteList(country).acco,
	quoteList(country).dome,
	quoteList(country).plaza,
	quoteList(country).shawmut,
	quoteList(country).bcci,
	quoteList(country).shiel,
	quoteList(country).holt,
	quoteList(country).hensel,
	quoteList(country).shawmut2,
]
