import * as React from 'react'
import { Div, Img } from 'glamorous'

import { gray02, gray65 } from 'shared/colors'
import Spacer from 'components/spacer'
import CenteredContent from './centered-content'
import withScreenWidth from 'shared/with-screen-width'
import { mobile } from 'shared/media-queries'

const LogoStrip = ({ screenWidth, wording, logos }) => {
	const tabletSize = screenWidth < 1024
	const mobileSize = screenWidth < 768
	let logosToDisplay = logos
	if (mobileSize) {
		logosToDisplay = logos.slice(0, 5)
	} else if (tabletSize) {
		logosToDisplay = logos.slice(0, 6)
	}

	return (
		<CenteredContent
			padding="35px 0 15px"
			backgroundColor={gray02}
			textAlign="center"
		>
			<Div fontSize="14px" fontWeight="600" color={gray65}>
				{wording}
			</Div>
			<Spacer height="20px" />
			<Div
				display="flex"
				alignItems="center"
				justifyContent="space-around"
				flexFlow="wrap"
				css={{
					[mobile]: {
						justifyContent: 'center',
					},
				}}
			>
				{logosToDisplay.map((logo, i) => (
					<Div display="flex" key={i} paddingBottom="20px" margin="0 10px">
						<Img {...logo} />
					</Div>
				))}
			</Div>
		</CenteredContent>
	)
}

export default withScreenWidth(LogoStrip)
