import React from 'react'
import { Div } from 'glamorous'

const CenteredContent = ({ children, ...other }) => (
	<Div display="flex" flexDirection="column" alignItems="center" {...other}>
		<Div maxWidth="1200px" width="90%">
			{children}
		</Div>
	</Div>
)

export default CenteredContent
