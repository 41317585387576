import * as React from 'react'
import styled from 'styled-components'
import Spacer from 'components/spacer'
import { Div } from 'glamorous'
import H1 from 'components/h1'
import H3 from 'components/h3'
import Image from './img'
import { mobile, tablet } from 'shared/media-queries'
import { numberOfSubsOnBCWording } from 'shared/constants'
import { ContentWrapper } from 'views/components'
import { currencySymbols } from 'shared/get-country'

const Br = styled.br`
	${mobile} {
		display: none;
	}
`

const ContentContainer = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	${tablet} {
		justify-content: center;
		text-align: center;
	}
`

const ImageContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`

const HeroContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	text-align: center;
	overflow: hidden;
	height: 620px;
	${tablet} {
		height: auto;
		min-height: 450px;
	}
	${mobile} {
		padding-bottom: 0px;
		height: 300px;
		min-height: 300px;
	}
`

const WordsContainer = styled.div`
	color: 'white';
	width: 100%;
	max-width: 100%;
	height: 100%;
	padding-top: 42px;
`

// prettier-ignore
interface Props {
	country: string;
	screenWidth: number;
}

// prettier-ignore
interface WordProps {
	heading: any;
	subHeading: any;
}

const Wording: React.FC<WordProps> = ({ heading, subHeading }) => {
	return (
		<WordsContainer>
			{heading}
			<Div height="10px" />
			{subHeading}
			<Spacer height="25px" />
		</WordsContainer>
	)
}
const WideHero: React.FC<Props> = ({ country, screenWidth }) => {
	return (
		<HeroContainer>
			<ImageContainer>
				<Image country={country} screenWidth={screenWidth} />
			</ImageContainer>
			<ContentWrapper position="relative" margin="none">
				<ContentContainer>
					<Wording
						heading={
							<H1
								color="white"
								css={{
									[mobile]: { fontSize: '26px' },
								}}
							>
								The world’s best contractors <Br />
								use BuildingConnected
							</H1>
						}
						subHeading={
							<H3
								color="white"
								css={{
									[mobile]: { fontSize: '16px' },
								}}
							>
								See why over {numberOfSubsOnBCWording} contractors choose
								BuildingConnected <Br />
								to build more than {currencySymbols[country]}1 trillion in
								construction a year
							</H3>
						}
					/>
				</ContentContainer>
			</ContentWrapper>
		</HeroContainer>
	)
}

export default WideHero
