import * as React from 'react'
import { Div, Img } from 'glamorous'
import _ from 'lodash'

import Spacer from 'components/spacer'
import CenteredContent from './centered-content'
import { laptop, tablet, mobile } from 'shared/media-queries'
import withScreenWidth from 'shared/with-screen-width'

const Quote = ({ logo, quote, imgSrc, name, title }) => (
	<Div position="relative">
		<Img {...logo} />
		<Spacer height="10px" />
		<Div position="absolute" left="-10px">
			“
		</Div>
		<Div fontSize="18px">{quote}”</Div>
		<Spacer height="15px" />
		<Div display="flex">
			<Img height="40px" width="40px" src={imgSrc} />
			<Spacer width="14px" />
			<Div>
				<Div fontSize="16px" fontWeight="700">
					{name}
				</Div>
				<Div fontSize="14px">{title}</Div>
			</Div>
		</Div>
	</Div>
)

const Quotes = ({ screenWidth, quotes }) => {
	const tabletSize = screenWidth <= 1023
	const mobileSize = screenWidth <= 767
	let quoteChunks = []

	if (mobileSize) {
		quoteChunks = _.chunk(quotes.slice(0, 6), 1)
	} else if (tabletSize) {
		quoteChunks = _.chunk(quotes.slice(0, 6), 2)
	} else {
		quoteChunks = _.chunk(quotes, 3)
	}

	return (
		<CenteredContent>
			{quoteChunks.map((quoteChunk, i) => (
				<Div key={i}>
					<Div
						display="flex"
						justifyContent="space-around"
						alignItems="baseline"
					>
						{quoteChunk.map((quote, i2) => (
							<Div
								display="flex"
								key={i2}
								flex="0 0 347px"
								css={{
									[laptop]: {
										flex: '0 0 250px',
									},
									[tablet]: {
										flex: '0 0 300px',
									},
									[mobile]: {
										flex: '1 1 0',
									},
								}}
							>
								<Quote {...quote} key={quote.name} />
							</Div>
						))}
					</Div>
					<Spacer height="100px" />
				</Div>
			))}
		</CenteredContent>
	)
}

export default withScreenWidth(Quotes)
