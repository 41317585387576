import directorDoorLogo from './img/director-door-logo.png'
import erikaTripoli from './img/erika-tripoli.png'
import innovativeMechanicalLogo from './img/innovative-mechanical-logo.png'
import kevinFrederik from './img/kevin-frederik.png'
import walkerLogo from './img/walker/walker-logo@2x.png'
import justinDavis from './img/walker/justin-davis@2x.png'
import seeleyBrothersLogo from './img/seeley-brothers/seeley-brothers-logo@2x.png'
import joeCalvillo from './img/seeley-brothers/joe-calvillo@2x.png'
import fiveStarLogo from './img/five-star/five-star-logo@2x.png'
import shawnPjesky from './img/five-star/shawn-pjesky@2x.png'
import bowmanFlooringLogo from './img/bowman-flooring/bowman-flooring-logo@2x.png'
import mikeAdams from './img/bowman-flooring/mike-adams@2x.png'
import daleysDrywallLogo from './img/daleys-drywall/daleys-drywall-logo@2x.png'
import jonathanHughes from './img/daleys-drywall/jonathan-hughes@2x.png'
import asiLogo from './img/asi/asi-logo@2x.png'
import micheleJensen from './img/asi/michele-jensen@2x.png'
import completeMillworkLogo from './img/complete-millwork/complete-millwork-logo@2x.png'
import kevinMortensen from './img/complete-millwork/kevin-mortensen@2x.png'
import canterburyLogo from './img/canterbury2x.png'
import bradleyConcreteLogo from './img/bradley-concrete/bradley-concrete-logo@2x.png'
import bayCityMechanicalLogo from './img/bay-city-mechanical/bay-city-mechanical-logo@2x.png'
import bensonLogo from './img/benson/benson-logo@2x.png'
import fsgLogo from './img/fsg/fsg-logo@2x.png'

export const logos = {
	directorDoor: {
		src: directorDoorLogo,
		width: '175px',
		height: '35px',
	},
	innovativeMechanical: {
		src: innovativeMechanicalLogo,
		width: '102px',
		height: '24px',
	},
	walker: {
		src: walkerLogo,
		width: '115px',
		height: '25px',
	},
	seeleyBrothers: {
		src: seeleyBrothersLogo,
		width: '70px',
		height: '38px',
	},
	fiveStar: {
		src: fiveStarLogo,
		width: '76px',
		height: '41px',
	},
	bowmanFlooring: {
		src: bowmanFlooringLogo,
		width: '49px',
		height: '50px',
	},
	daleysDrywall: {
		src: daleysDrywallLogo,
		width: '83px',
		height: '42px',
	},
	asi: {
		src: asiLogo,
		width: '73px',
		height: '25px',
	},
	completeMillwork: {
		src: completeMillworkLogo,
		width: '99px',
		height: '28px',
	},
	canterbury: {
		src: canterburyLogo,
		width: '69px',
		height: '22px',
	},
	bradleyConcrete: {
		src: bradleyConcreteLogo,
		width: '111px',
		height: '20px',
	},
	bayCityMechanical: {
		src: bayCityMechanicalLogo,
		width: '94px',
		height: '23px',
	},
	benson: {
		src: bensonLogo,
		width: '112px',
		height: '14px',
	},
	fsg: {
		src: fsgLogo,
		width: '44px',
		height: '22px',
	},
}

export const quotes = [
	{
		logo: logos.completeMillwork,
		quote: `Bid Board Pro is the best way I've seen to aggregate all the different opportunities that come into your company and keep track of them in one place.`,
		imgSrc: kevinMortensen,
		name: 'Kevin Mortensen',
		title: 'VP of Preconstruction',
	},
	{
		logo: logos.directorDoor,
		quote: `BuildingConnected has been a game changer in our office, it keeps all of our projects organized in a clear and concise format. It has made managing our bids so much more efficient.`,
		imgSrc: erikaTripoli,
		name: 'Erika Tripoli',
		title: 'Executive Assistant',
	},
	{
		logo: logos.innovativeMechanical,
		quote: `Now we use BuildingConnected to export our calendar invites to outlook so we're hitting all our job walks and big dates.`,
		imgSrc: kevinFrederik,
		name: 'Kevin Frederik',
		title: 'VP of Business Development',
	},
	{
		logo: logos.walker,
		quote: `Bid Board Pro simplifies our bid/budget tracking needs across our entire company. We’re able to access all opportunities in one place while providing real time analytics to our executive leadership quickly and confidently.`,
		imgSrc: justinDavis,
		name: 'Justin Davis',
		title: 'VP of Construction',
	},
	{
		logo: logos.seeleyBrothers,
		quote: `The best thing about Bid Board Pro is that is has eliminated the redundant e-mails that we would forward to one another regarding bid invitations.`,
		imgSrc: joeCalvillo,
		name: 'Joe Calvillo',
		title: 'Partner',
	},
	{
		logo: logos.fiveStar,
		quote: `Your system of being able to send it to invites@bidboard.buildingconnected.com  is a beautiful thing. I would tell anybody it will change their life.`,
		imgSrc: shawnPjesky,
		name: 'Shawn Pjesky',
		title: 'Business Development',
	},
	{
		logo: logos.bowmanFlooring,
		quote: `As a sub-contractor, Bid Board Pro has really changed the way we do business. The ability to track bids, run reports and analyze sales are only a few clicks away.`,
		imgSrc: mikeAdams,
		name: 'Mike Adams',
		title: 'Senior Project Manager',
	},
	{
		logo: logos.daleysDrywall,
		quote: `The ability to manage all of our bid invites in one place. With the abundance of job opportunities in the current market, it give us the visibility we need to plan our work flow accurately and successfully.`,
		imgSrc: jonathanHughes,
		name: 'Jonathan Hughes',
		title: 'Chief Estimator',
	},
	{
		logo: logos.asi,
		quote: `Sending the invitation received to the BuildingConnected email address has saved our company time and resources since we do not have to perform manual entries for each opportunity.`,
		imgSrc: micheleJensen,
		name: 'Michele Jensen',
		title: 'Inside Sales',
	},
]

export const otherQuotes = [
	{
		logo: logos.directorDoor,
		quote: `BuildingConnected has been a game changer in our office, it keeps all of our projects organised in a clear and concise format. It has made managing our tenders so much more efficient.`,
		imgSrc: erikaTripoli,
		name: 'Erika Tripoli',
		title: 'Executive Assistant',
	},
	{
		logo: logos.daleysDrywall,
		quote: `The ability to manage all of our tender invites in one place. With the abundance of job opportunities in the current market, it give us the visibility we need to plan our work flow accurately and successfully.`,
		imgSrc: jonathanHughes,
		name: 'Jonathan Hughes',
		title: 'Chief Estimator',
	},
	{
		logo: logos.asi,
		quote: `Sending the invitation received to the BuildingConnected email address has saved our company time and resources since we do not have to perform manual entries for each opportunity.`,
		imgSrc: micheleJensen,
		name: 'Michele Jensen',
		title: 'Inside Sales',
	},
]
