import * as React from 'react'
import { Div } from 'glamorous'

import Spacer from 'components/spacer'
import { laptop, tablet } from 'shared/media-queries'

const LargeButton = ({ color, title, onClick }) => (
	<Div
		height="100px"
		borderRadius="2px"
		boxShadow="0 2px 11px 0 rgba(0,0,0,0.2)"
		backgroundColor={color}
		onClick={onClick}
		color="white"
		display="flex"
		flexDirection="column"
		alignItems="center"
		justifyContent="center"
		cursor="pointer"
		flex="0 0 487px"
		css={{
			[laptop]: {
				flex: '0 0 420px',
			},
			[tablet]: {
				flex: '0 0 325px',
			},
		}}
	>
		<Div fontSize="20px" fontWeight="700" letterSpacing="1px">
			{title}
		</Div>
		<Spacer height="5px" />
		<Div fontSize="18px" fontWeight="500">
			View testimonials &raquo;
		</Div>
	</Div>
)

export default LargeButton
