import React from 'react'
import { Div } from 'glamorous'
import mirnaLarge from '../img/bcci/mirna-large.png'
import mirnaSmall from '../img/bcci/mirna-small.png'
import bcciLogo from '../img/bcci/bcci-logo-white.png'
import VideoQuote from './video-quote'
import VideoThumbnails from './video-thumbnails'
import holtThumb from '../img/holt-thumb.png'
import holtLogo from '../img/holt-logo.png'
import shawmutThumb from '../img/shawmut-thumb.png'
import holtLarge from '../img/holt-taylor-large.png'
import bcciThumb from '../img/bcci/bcci-thumb.png'
import plazaThumb from '../img/plaza-thumb.png'
import { mobile } from 'shared/media-queries'
import { isUSA } from 'shared/get-country'

const Spacer = () => (
	<Div
		height="30px"
		css={{
			[mobile]: { height: '3px' },
		}}
	/>
)

const GCVideoQuotes = () => {
	return (
		<>
			<VideoQuote
				largeImg={mirnaLarge}
				smallImg={mirnaSmall}
				videoId="zkrzkzuvt9"
				logo={{ src: bcciLogo, width: '71px', height: '64px' }}
			/>
			<Spacer />
			<VideoThumbnails
				thumbnails={[
					{
						imgSrc: holtThumb,
						videoId: 'd96q1wvbyz',
					},
					{
						imgSrc: shawmutThumb,
						videoId: 'rterry3vhv',
					},
					{
						imgSrc: plazaThumb,
						videoId: 'o71tq35sde',
					},
				]}
			/>
		</>
	)
}

const HCVideoQuotes = () => {
	return (
		<>
			<VideoQuote
				largeImg={holtLarge}
				smallImg={holtThumb}
				videoId="d96q1wvbyz"
				logo={{ src: holtLogo, width: '71px', height: '64px' }}
			/>
			<Spacer />
			<VideoThumbnails
				thumbnails={[
					{
						imgSrc: bcciThumb,
						videoId: 'zkrzkzuvt9',
					},
					{
						imgSrc: shawmutThumb,
						videoId: 'rterry3vhv',
					},
					{
						imgSrc: plazaThumb,
						videoId: 'o71tq35sde',
					},
				]}
			/>
		</>
	)
}

const VideoQuotes = country =>
	isUSA(country) ? <GCVideoQuotes /> : <HCVideoQuotes />

export default VideoQuotes
