import * as React from 'react'
import { countryCodes } from 'shared/get-country'

import { default as Image } from './us'
import { default as ImageANZ } from './anz'
import { default as ImageUK } from './uk'
import { default as ImageIE } from './ie'

const images = ({ country, screenWidth }) => {
	const i = {
		[countryCodes.us]: <Image screenWidth={screenWidth} />,
		[countryCodes.nz]: <ImageANZ screenWidth={screenWidth} />,
		[countryCodes.au]: <ImageANZ screenWidth={screenWidth} />,
		[countryCodes.uk]: <ImageUK screenWidth={screenWidth} />,
		[countryCodes.ie]: <ImageIE screenWidth={screenWidth} />,
	}
	return i[country]
}

const constants = ({ country, screenWidth }) => images({ country, screenWidth })
export default constants
