import * as React from 'react'
import { Div, Img } from 'glamorous'

import { tablet, mobile } from 'shared/media-queries'
import withScreenWidth from 'shared/with-screen-width'
import { WistiaVideoOverlay } from 'views/components'
import WatchVideoIcon from 'imgs/icons/play-button.svg'

const VideoQuote = ({
	screenWidth,
	largeImg,
	smallImg,
	videoId,
	logo,
	leftQuote,
}) => {
	const tabletSize = screenWidth < 1024
	const mobileSize = screenWidth < 768

	return (
		<Div display="flex" justifyContent="center">
			<Div maxWidth="1440px" position="relative" width="100%" overflow="hidden">
				<Img
					src={tabletSize ? smallImg : largeImg}
					width="100%"
					css={{
						[tablet]: {
							float: leftQuote ? 'right' : undefined,
						},
						[mobile]: {
							height: 'auto',
							width: '100%',
						},
					}}
				/>
				<Div position="absolute" top="0" left="0" height="100%" width="100%">
					<Div
						display="flex"
						justifyContent="center"
						alignItems="center"
						height="100%"
					>
						<WatchVideoIcon
							fill="white"
							width={mobileSize ? '40px' : '70px'}
							height={mobileSize ? '40px' : '70px'}
						/>
						<Img
							{...logo}
							position="absolute"
							bottom="40px"
							left="40px"
							display="none"
							css={{
								[tablet]: {
									display: 'block',
								},
								[mobile]: {
									bottom: '14px',
									left: '14px',
								},
							}}
						/>
					</Div>
				</Div>
				<WistiaVideoOverlay videoId={videoId} />
			</Div>
		</Div>
	)
}

export default withScreenWidth(VideoQuote)
