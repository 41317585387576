import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const MapImage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				filename: file(
					relativePath: {
						eq: "customers/components/project-map/img/other-image.png"
					}
				) {
					childImageSharp {
						fluid(maxHeight: 617) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return <GatsbyImage fluid={data.filename.childImageSharp.fluid} alt="map" />
}

export default MapImage
