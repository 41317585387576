import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { breakpoints } from 'shared/media-queries'
import GatsbyImage from 'gatsby-image'

const DiscoverImage = ({ screenWidth }) => {
	const data = useStaticQuery(
		graphql`
			query {
				wide: file(
					relativePath: {
						eq: "customers/components/hero-image/img/anz/desktop-anz.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 4880) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				desktop: file(
					relativePath: {
						eq: "customers/components/hero-image/img/anz/desktop-anz.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 2880) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				laptop: file(
					relativePath: {
						eq: "customers/components/hero-image/img/anz/laptop-anz.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1440) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				tablet: file(
					relativePath: {
						eq: "customers/components/hero-image/img/anz/tablet-anz.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 420) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)
	if (screenWidth <= breakpoints.tablet) {
		return (
			<GatsbyImage
				fluid={data.tablet.childImageSharp.fluid}
				alt="customers hero"
				style={{ height: '100%' }}
				imgStyle={{ objectPosition: 'center bottom' }}
			/>
		)
	} else if (screenWidth < breakpoints.laptop) {
		return (
			<GatsbyImage
				fluid={data.laptop.childImageSharp.fluid}
				alt="customers hero"
				style={{ height: '100%' }}
				imgStyle={{ objectPosition: 'center bottom' }}
			/>
		)
	} else if (screenWidth <= breakpoints.desktop) {
		return (
			<GatsbyImage
				fluid={data.desktop.childImageSharp.fluid}
				alt="customers hero"
				style={{ height: '100%' }}
				imgStyle={{ objectPosition: 'center bottom' }}
			/>
		)
	}
	return (
		<GatsbyImage
			fluid={data.wide.childImageSharp.fluid}
			alt="customers hero"
			style={{ height: '100%' }}
			imgStyle={{ objectPosition: 'center bottom' }}
		/>
	)
}

export default DiscoverImage
