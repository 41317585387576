import { countryCodes } from 'shared/get-country'

const usCopy = {
	gcSection: {
		title: 'General Contractors',
		subtitle:
			'Over 4,000 general contractors build every day with BuildingConnected',
		stats: {
			top: {
				wording: 'of the top 20 GCs in the US',
			},
			subs: {
				wording: 'subcontractors on the BuildingConnected network',
			},
		},
	},
	subsSection: {
		first: {
			wording: 'Projects bid out last month',
		},
		second: {
			wording: 'Bid out monthly',
		},
		third: {
			wording: 'Bid invites sent each month',
		},
	},
	buttons: {
		gc: {
			title: 'GENERAL CONTRACTORS',
		},
		subs: {
			title: 'SUBCONTRACTORS',
		},
	},
	footer: {
		title: 'The world’s best contractors use BuildingConnected.',
		buttonText: 'Request a demo',
	},
}

const anzCopy = {
	gcSection: {
		title: 'Head Contractors',
		subtitle:
			'Over 4,000 Head Contractors build every day with BuildingConnected',
		stats: {
			top: {
				wording: 'of the top 20 Head Contractors in North America ',
			},
			subs: {
				wording: 'subcontractors on the BC network',
			},
		},
	},
	subsSection: {
		first: {
			wording: 'Projects tendered out last month',
		},
		second: {
			wording: 'Tendered out monthly',
		},
		third: {
			wording: 'Tender invites sent each month',
		},
	},
	buttons: {
		gc: {
			title: 'HEAD CONTRACTORS',
		},
		subs: {
			title: 'SUBCONTRACTORS',
		},
	},
	footer: {
		title: 'See why we’re the #1 tool in the industry ',
		buttonText: 'Contact Sales',
	},
}

const ukCopy = {
	gcSection: {
		title: 'Main Contractors',
		subtitle:
			'Over 4,000 Main Contractors build every day with BuildingConnected',
		stats: {
			top: {
				wording: 'of the top 20 Main Contractors in North America ',
			},
			subs: {
				wording: 'subcontractors on the BC network',
			},
		},
	},
	subsSection: anzCopy.subsSection,
	buttons: { ...anzCopy.buttons, gc: { title: 'MAIN CONTRACTORS' } },
	footer: anzCopy.footer,
}

const copy = {
	[countryCodes.us]: usCopy,
	[countryCodes.au]: anzCopy,
	[countryCodes.nz]: anzCopy,
	[countryCodes.uk]: ukCopy,
	[countryCodes.ie]: ukCopy,
}

const content = country => copy[country]

export default content
