import * as React from 'react'
import { A } from 'glamorous'

import { lightBlue } from '../shared/colors'

const Link = ({ href, children }) => (
	<A href={href} fontWeight={500} color={lightBlue}>
		{children}
	</A>
)

export default Link
