import React from 'react'
import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Div, Br } from 'glamorous'
import _ from 'lodash'

import Layout from 'components/layout'
import HeroImage from './components/hero-image'
import Spacer from 'components/spacer'
import H1 from 'components/h1'
import ProjectMap from './components/project-map'
import LargeButton from './components/large-button'
import { darkBlue, lightBlue, gray60 } from 'shared/colors'
import H2 from 'components/h2'
import Stat from './components/stat'
import Quotes from './components/quotes'
import * as gcInfo from './gcs'
import LogoStrip from './components/logo-strip'
import * as subInfo from './subs'
import Link from 'components/link'
import ButtonLink from 'components/button-link'
import CenteredContent from './components/centered-content'
import { laptop, tablet, mobile } from 'shared/media-queries'
import { showRequestDemoModal } from 'components/modal-manager'
import useScreenWidth from 'hooks/use-screen-width'
import { numberOfSubsOnBC, numberOfSubsOnBCWording } from 'shared/constants'
import generateContent from './content'
import withCountry, {
	contractorTypeByLocale,
	currencySymbols,
	isUSA,
} from 'shared/get-country'
import gcVideoQuotes from './components/gc-video-quotes'
import subsVideoQuotes from './components/subs-video-quotes'

const scrollToSection = ref => {
	const node = ReactDOM.findDOMNode(ref.current)
	const top = node.offsetTop - 100
	window.scrollTo(0, top)
}

const CustomersPage = props => {
	const subsSectionRef = useRef(null)
	const gcSectionRef = useRef(null)

	useEffect(() => {
		const search = _.get(props, 'location.search', '').toLowerCase()

		if (search.includes('subs')) {
			_.defer(() => scrollToSection(subsSectionRef))
		} else if (search.includes('gcs')) {
			_.defer(() => scrollToSection(gcSectionRef))
		}
	})

	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}

	const { country } = props
	const content = generateContent(country)
	if (!country) return null
	const { buttons, gcSection, subsSection, footer } = content
	const currencySymbol = currencySymbols[country]
	return (
		<Layout
			title="Meet Our Customers | BuildingConnected"
			description="Learn why the top general contractors and subcontractors choose BuildingConnected for their preconstruction software solution."
		>
			<HeroImage country={country} screenWidth={screenWidth} />
			<Div textAlign="center">
				<Spacer height="55px" />
				<CenteredContent>
					<H1
						css={{
							[laptop]: {
								fontSize: '38px',
							},
						}}
					>
						<Div
							css={{
								[mobile]: {
									fontSize: '29px',
								},
							}}
						>
							The largest construction network powering{' '}
							<Br
								css={{
									[mobile]: {
										display: 'none',
									},
								}}
							/>
							the world’s top contractors since 2012
						</Div>
					</H1>
				</CenteredContent>
				<ProjectMap country={country} />
				<Div
					height="75px"
					css={{
						[mobile]: {
							height: '30px',
						},
					}}
				/>
				<Div
					display="flex"
					justifyContent="center"
					css={{
						[mobile]: {
							display: 'block',
						},
					}}
				>
					<LargeButton
						color={darkBlue}
						title={buttons.gc.title}
						onClick={() => scrollToSection(gcSectionRef)}
					/>
					<Div
						width="30px"
						css={{
							[mobile]: {
								height: '20px',
								width: '0',
							},
						}}
					/>
					<LargeButton
						color={lightBlue}
						title={buttons.subs.title}
						onClick={() => scrollToSection(subsSectionRef)}
					/>
				</Div>
			</Div>
			<CenteredContent
				css={{
					paddingTop: '100px',
					[laptop]: {
						paddingTop: '69px',
					},
					[tablet]: {
						paddingTop: '55px',
					},
				}}
			>
				<Div textAlign="center" ref={gcSectionRef}>
					<H2>{gcSection.title}</H2>
					<Div height="10px" />
					<Div
						fontSize="24px"
						paddingBottom="30px"
						css={{
							[laptop]: {
								paddingBottom: '50px',
							},
							[tablet]: {
								fontSize: '22px',
							},
							[mobile]: {
								fontSize: '16px',
							},
						}}
					>
						{gcSection.subtitle}
					</Div>
					<Div
						display="flex"
						justifyContent="space-around"
						css={{
							[mobile]: {
								display: 'block',
							},
						}}
					>
						<Stat number="13" wording={gcSection.stats.top.wording} />
						<Stat
							number={`${numberOfSubsOnBCWording}+`}
							wording={gcSection.stats.subs.wording}
						/>
						<Stat
							number={`${currencySymbol}100B`}
							wording="in construction each month"
						/>
					</Div>
				</Div>
			</CenteredContent>
			<Div
				height="50px"
				css={{
					[tablet]: { height: '35px' },
				}}
			/>
			{gcVideoQuotes(country)}
			<Div
				height="100px"
				css={{
					[tablet]: { height: '58px' },
				}}
			/>
			<Quotes
				quotes={
					isUSA(country) ? gcInfo.quotes(country) : gcInfo.otherQuotes(country)
				}
			/>
			<LogoStrip
				wording={`Join over 2,500 ${contractorTypeByLocale(
					country
				)} using BuildingConnected every day`}
				logos={[
					gcInfo.logos.skanska,
					gcInfo.logos.hoar,
					gcInfo.logos.alston,
					gcInfo.logos.henselPhelps,
					gcInfo.logos.novo,
				]}
			/>
			<CenteredContent
				css={{
					paddingTop: '100px',
					[laptop]: {
						paddingTop: '69px',
					},
					[tablet]: {
						paddingTop: '55px',
					},
				}}
			>
				<Div textAlign="center" ref={subsSectionRef}>
					<H2>Subcontractors</H2>
					<Div
						fontSize="24px"
						paddingBottom="30px"
						css={{
							[laptop]: {
								paddingBottom: '50px',
							},
							[tablet]: {
								fontSize: '22px',
							},
							[mobile]: {
								fontSize: '16px',
								paddingBottom: '30px',
							},
						}}
					>
						Powering preconstruction every day for over{' '}
						<Br
							css={{
								[mobile]: { display: 'none' },
							}}
						/>
						{numberOfSubsOnBC} of the world’s top subcontractors
					</Div>
					<Div
						display="flex"
						justifyContent="space-around"
						css={{
							[mobile]: {
								display: 'block',
							},
						}}
					>
						<Stat number="10K+" wording={subsSection.first.wording} />
						<Stat
							number={`${currencySymbol}100B`}
							wording={subsSection.second.wording}
						/>
						<Stat number="2M+" wording={subsSection.third.wording} />
					</Div>
				</Div>
			</CenteredContent>
			<Div
				height="50px"
				css={{
					[tablet]: { height: '35px' },
				}}
			/>
			{subsVideoQuotes(country)}
			<Quotes quotes={isUSA(country) ? subInfo.quotes : subInfo.otherQuotes} />
			<LogoStrip
				wording={`Join over ${numberOfSubsOnBC} subcontractors using BuildingConnected every day`}
				logos={[
					subInfo.logos.canterbury,
					subInfo.logos.completeMillwork,
					subInfo.logos.innovativeMechanical,
					subInfo.logos.bradleyConcrete,
					subInfo.logos.bayCityMechanical,
					subInfo.logos.walker,
					subInfo.logos.benson,
					subInfo.logos.fsg,
				]}
			/>
			<Div
				height="125px"
				css={{
					[laptop]: { height: '73px' },
				}}
			/>
			<CenteredContent>
				<Div
					display="flex"
					css={{
						[tablet]: {
							display: 'block',
						},
					}}
				>
					<Div flex="2 2 0">
						<H1>
							<Div
								css={{
									width: '700px',
									[laptop]: {
										width: '600px',
										fontSize: '46px',
									},
									[tablet]: {
										width: 'inherit',
									},
									[mobile]: {
										fontSize: '29px',
									},
								}}
							>
								{footer.title}
							</Div>
						</H1>
						<Spacer height="20px" />
						<Div
							width="200px"
							css={{
								[mobile]: {
									width: '100%',
								},
							}}
						>
							<ButtonLink
								bgColor={lightBlue}
								width="100%"
								onClick={() => showRequestDemoModal()}
							>
								Request a demo
							</ButtonLink>
						</Div>
					</Div>
					<Div
						css={{
							[tablet]: { height: '68px' },
							[mobile]: { height: '48px' },
						}}
					/>
					<Div display="flex" alignItems="center" flex="1 1 0">
						<Div
							height="200px"
							backgroundColor={gray60}
							flex="0 0 1px"
							css={{
								[tablet]: {
									display: 'none',
								},
							}}
						/>
						<Div
							width="30px"
							css={{
								[tablet]: { display: 'none' },
							}}
						/>
						<Div>
							<Div fontSize="22px" fontWeight="600">
								Share the love!
							</Div>
							<Spacer height="10px" />
							<Div
								fontSize="18px"
								css={{
									[tablet]: {
										maxWidth: '397px',
									},
								}}
							>
								Tell us how BuildingConnected helps in your process, and you
								could be featured on this page.
							</Div>
							<Spacer height="10px" />
							<Div fontSize="18px">
								<Link href="https://buildingconnected.zendesk.com/hc/en-us?solvvy=true">
									Need Help?
								</Link>
							</Div>
						</Div>
					</Div>
				</Div>
			</CenteredContent>
			<Spacer height="125px" />
		</Layout>
	)
}

export default withCountry(CustomersPage)
