import * as React from 'react'
import { Div } from 'glamorous'

import { darkBlue } from 'shared/colors'
import { tablet, mobile } from 'shared/media-queries'

const Stat = ({ number, wording }) => (
	<Div
		flex="0 0 275px"
		css={{
			[tablet]: {
				flex: '0 0 215px',
			},
		}}
	>
		<Div
			fontSize="48px"
			fontWeight="700"
			color={darkBlue}
			css={{
				[tablet]: {
					fontSize: '38px',
				},
				[mobile]: {
					fontSize: '32px',
				},
			}}
		>
			{number}
		</Div>
		<Div
			fontSize="18px"
			css={{
				[mobile]: {
					paddingBottom: '18px',
				},
			}}
		>
			{wording}
		</Div>
	</Div>
)

export default Stat
