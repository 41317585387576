import * as React from 'react'
import { Div, Img } from 'glamorous'

import withScreenWidth from 'shared/with-screen-width'
import { WistiaVideoOverlay } from 'views/components'
import WatchVideoIcon from 'imgs/icons/play-button.svg'

const VideoThumbnail = ({ imgSrc, videoId, mobileSize }) => (
	<Div position="relative">
		<Img height="100%" width="100%" src={imgSrc} />
		<Div position="absolute" top="0" left="0" height="100%" width="100%">
			<Div
				display="flex"
				justifyContent="center"
				alignItems="center"
				height="100%"
			>
				<WatchVideoIcon
					height={mobileSize ? '20px' : '49px'}
					width={mobileSize ? '20px' : '49px'}
					fill="white"
				/>
			</Div>
		</Div>
		<WistiaVideoOverlay videoId={videoId} />
	</Div>
)

const VideoThumbnails = ({ screenWidth, thumbnails }) => {
	const mobileSize = screenWidth < 767

	return (
		<Div display="flex" justifyContent="center">
			<Div
				maxWidth="1200px"
				display="flex"
				width="90%"
				justifyContent="space-around"
			>
				{thumbnails.map(({ imgSrc, videoId }) => (
					<Div display="flex" flex="1 1 0%" padding="0 1%" key={videoId}>
						<VideoThumbnail
							imgSrc={imgSrc}
							videoId={videoId}
							mobileSize={mobileSize}
						/>
					</Div>
				))}
			</Div>
		</Div>
	)
}

export default withScreenWidth(VideoThumbnails)
